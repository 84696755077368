import { useState, useEffect, useContext } from "react";
import { getCookie } from "../../utils/Utils";

const useStorageUsage = (apiUrl) => {
  const [storageDetails, setStorageDetails] = useState({
    planStorageText: "0",
    usedStorageText: "0",
    percentageUsed: 0,
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  // const { setStorageFiledAlert } = useContext(MyContext);

  useEffect(() => {
    const fetchStorageDetails = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_V1_URL}storage/get-user-plan`, {
          method: "GET",
          headers: {
            authToken: getCookie("authToken", null),
          },
        });

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }
        const data = await response.json();
        // console.log(data);
        if (data && data.data) {
          const { storagePlan, usedStorage } = data.data;
          // setStorageFiledAlert(data?.data.storageFilledAlert);
          const planStorageText = storagePlan.features.storage.text;
          const usedStorageText = usedStorage.text;

          const planStorageValue = storagePlan.features.storage.value;
          const planStorageUnit = storagePlan.features.storage.unit;
          const usedStorageValue = usedStorage.value;
          const usedStorageUnit = usedStorage.unit;

          const planStorageInMB = planStorageUnit === "GB" ? planStorageValue * 1024 : planStorageValue;
          const usedStorageInMB = usedStorageUnit === "GB" ? usedStorageValue * 1024 : usedStorageValue;

          const percentageUsed = (usedStorageInMB / planStorageInMB) * 100;

          setStorageDetails({
            planStorageText,
            usedStorageText,
            percentageUsed: percentageUsed.toFixed(2), // to keep two decimal points
          });
        }
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchStorageDetails();
  }, [apiUrl]);

  return { storageDetails, loading, error };
};

export default useStorageUsage;
