import React, { useState } from "react";
import Content from "../layout/content/Content";
import Head from "../layout/head/Head";
import { DropdownToggle, DropdownMenu, UncontrolledDropdown, DropdownItem, Card } from "reactstrap";
import {
  Block,
  BlockDes,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  Button,
  Row,
  Col,
} from "../components/Component";
import Slider from "react-slick";
import { Link, useNavigate } from "react-router-dom";
import { fetchConnectAPI } from "../utils/Utils";
import { toast } from "react-toastify";
import ConnectShimmer from "./connect/ConnectShimmer";

const ConnectHome = () => {
  const [link, setLink] = useState("");
  const navigate = useNavigate();
  const [startMeeting, setStartMeeting] = useState(false);
  const SlideData = [
    {
      id: "1",
      image: `https://api.silocloud.io/assets/images/connect/slider/slider-1.png`,
      text: "Click on 'Go live' to get a link that you can send to people that you want to meet with",
      headText: "Get a link that you can share",
    },
    {
      id: "2",
      image: `https://api.silocloud.io/assets/images/connect/slider/slider-2.png`,
      text: "No one can join a meeting unless invited or admitted by the host",
      headText: "Your meeting is safe",
    },
  ];
  const settings = {
    className: "slider-init",
    slidesToShow: 1,
    centerMode: false,
    slidesToScroll: 1,
    infinite: false,
    responsive: [
      { breakpoint: 1539, settings: { slidesToShow: 1 } },
      { breakpoint: 992, settings: { slidesToShow: 1 } },
      { breakpoint: 768, settings: { slidesToShow: 1 } },
    ],
    arrows: false,
    dots: true,
  };
  const goLiveClick = async () => {
    toast.info("Creating meeting...");
    setStartMeeting(true);
    let fd = new FormData();
    let createMeetingResponse = await fetchConnectAPI(fd, "create-meeting-code");
    if (createMeetingResponse.status === 200) {
      if (createMeetingResponse?.response?.status) {
        toast.success(createMeetingResponse?.response?.message);
        let createMeetingResponseData = createMeetingResponse?.response?.data;
        let localMeetingData = {
          room_name: createMeetingResponseData.room_name,
          is_admin: createMeetingResponseData.is_admin,
        };
        let localSettingData = {
          mic: createMeetingResponseData.setting.mic,
          camera: createMeetingResponseData.setting.camera,
        };
        localStorage.setItem("connect_setting", JSON.stringify(localSettingData));
        localStorage.setItem(createMeetingResponseData.room_name, JSON.stringify(localMeetingData));
        navigate(`connect/${createMeetingResponseData.room_name}`);
      } else {
        toast.error(createMeetingResponse?.response?.message);
      }
    } else {
      toast.info("Please try again");
    }
    setStartMeeting(false);
  };
  const joinMeeting = () => {
    navigate(`connect/${link}`);
  };
  if (startMeeting) return <ConnectShimmer />;
  return (
    <React.Fragment>
      <Head title="Connect" />
      <Content>
        <BlockHead size="sm" className="d-none">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                {process.env.REACT_APP_PROJECT_NAME} Connect
              </BlockTitle>
              <BlockDes className="text-soft d-none">
                <p>Welcome to Analytics Dashboard.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent></BlockHeadContent>
          </div>
        </BlockHead>

        <Block>
          <Row className="">
            <Col sm={12} lg={6} className="text-center my-auto">
              <div className="" style={{ height: "calc(100vh-20px)" }}>
                <div className="m-5">
                  <h2>Video calls and meetings for everyone</h2>
                </div>
                <div className="m-5">
                  <p className="" style={{ fontWeight: "700" }}>
                    Connect, collaborate and celebrate from anywhere with {process.env.REACT_APP_PROJECT_NAME} Connect
                  </p>
                </div>
                <div className="d-flex justify-content-center g-gs-2">
                  <Button color="primary" onClick={goLiveClick}>
                    Go Live
                  </Button>
                  <input
                    className="form-control form-control-sm mx-2 w-50"
                    placeholder="Enter a 9 character meeting code"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                  />
                  <Button color="secondary" disabled={link.length != 9} onClick={joinMeeting}>
                    Join
                  </Button>
                </div>
              </div>
            </Col>
            <Col sm={12} lg={6}>
              <Slider {...settings}>
                {SlideData.map((item) => (
                  <div key={item.id} className=" my-auto px-3 align-items-center text-center">
                    <img
                      src={item.image}
                      className="card-img-top"
                      alt=""
                      style={{ height: "300px", width: "300px", margin: "auto", objectFit: "contain" }}
                    />
                    <div className="card-inner">
                      <h6>{item.headText}</h6>
                      <p className="card-text">{item.text}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </Col>
          </Row>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default ConnectHome;
