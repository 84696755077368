import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Head from "./head/Head";
import Header from "./header/Header";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";

const PublicConnectLayout = ({ title, app, ...props }) => {
  return (
    <>
      <ToastContainer />
      <Head title={!title && "Loading"} />
      <AppRoot>
        <AppMain>
          <AppWrap nkWrap={false} styles={{ paddingTop: "65px" }}>
            <Header fixed publicMeeting={true} />
            <Outlet />
            {/* <Footer /> */}
          </AppWrap>
        </AppMain>
      </AppRoot>
    </>
  );
};
export default PublicConnectLayout;
