import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import ConnectLayout from "../layout/ConnectLayout";
import ConnectHome from "../pages/ConnectHome";
import Connect from "../pages/Connect";
import ConnectShimmer from "../pages/connect/ConnectShimmer";
import PublicConnect from "../pages/PublicConnect";
import PublicConnectLayout from "../layout/PublicConnectLayout";

const Pages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  return (
    <Routes>
      <Route path={`${process.env.PUBLIC_URL}`} element={<PublicConnectLayout />}>
        <Route path="/public/:room_name" element={<PublicConnect />}></Route>
      </Route>
      <Route path={`${process.env.PUBLIC_URL}`} element={<ConnectLayout />}>
        <Route index element={<ConnectHome />}></Route>
        <Route path="/connect/:room_name" element={<Connect />}></Route>
        <Route path="/shimmer" element={<ConnectShimmer />}></Route>
      </Route>
    </Routes>
  );
};
export default Pages;
