import React, { useEffect, useState } from "react";
import classNames from "classnames";
import Toggle from "../sidebar/Toggle";
import User from "./dropdown/user/User";
import Notification from "./dropdown/notification/Notification";
import AppDropdown from "./dropdown/app/App";
import ChatDropdown from "./dropdown/chat/Chat";
import { Icon } from "../../components/Component";
import { Link, useLocation } from "react-router-dom";
import "../../pages/css/common.css";
import connect_white_logo from "../../images/connect_white.png";
import connect_dark_logo from "../../images/connect_dark.png";
import { useTheme, useThemeUpdate } from "../provider/Theme";
import style from "react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark";
import { fetchConnectAPI } from "../../utils/Utils";

const Header = ({ fixed, className, publicMeeting }) => {
  const theme = useTheme();
  const themeUpdate = useThemeUpdate();
  const headerClass = classNames({
    "nk-header": true,
    "nk-header-fixed": fixed,
    [`is-light`]: theme.header === "white",
    [`is-${theme.header}`]: theme.header !== "white" && theme.header !== "light",
    [`${className}`]: className,
  });
  let currentUrl;

  if (window.location.pathname !== undefined) {
    currentUrl = window.location.pathname;
  } else {
    currentUrl = null;
  }
  let nkHeaderWrapStyle = {};
  if (publicMeeting) {
    nkHeaderWrapStyle = {
      paddingTop: "14px",
      paddingBottom: "14px",
    };
  }
  const [fullScreenOn, setFullScreenOn] = useState(false);
  useEffect(() => {
    const fetchTheme = async () => {
      try {
        const data = new FormData();
        data.append("action", "fetch");
        const settingRes = await fetchConnectAPI(data, "public/frontend-settings", false);
        if (settingRes.status == 200) {
          if (settingRes.response.status) {
            const fetchedTheme = settingRes?.data?.frontend_settings?.theme;
            themeUpdate.skin(fetchedTheme === "1" ? "light" : "dark");
          }
        }
      } catch (error) {
        console.log(error);
      }
    };
    fetchTheme();
  }, [fullScreenOn]);

  const location = useLocation();
  const toggleFullScreen = () => {
    setFullScreenOn(!fullScreenOn);
    if (
      document.fullscreenElement ||
      document.webkitFullscreenElement ||
      document.mozFullScreenElement ||
      document.msFullscreenElement
    ) {
      // Exit fullscreen mode
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      }
    } else {
      const element = document.documentElement;
      if (element.requestFullscreen) {
        element.requestFullscreen();
      } else if (element.webkitRequestFullscreen) {
        element.webkitRequestFullscreen();
      } else if (element.mozRequestFullScreen) {
        element.mozRequestFullScreen();
      } else if (element.msRequestFullscreen) {
        element.msRequestFullscreen();
      }
    }
  };
  // State for time and date
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    const updateTime = () => {
      const now = new Date();
      const hours = now.getHours();
      const minutes = now.getMinutes();
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 || 12;
      const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;

      setTime(`${formattedHours}:${formattedMinutes} ${ampm}`);

      const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
      const day = days[now.getDay()];
      const date = now.getDate();
      let month = now.getMonth() + 1;
      month = month < 10 ? `0${month}` : month;
      const year = now.getFullYear();

      setDate(`${day}, ${date}/${month}/${year}`);
    };

    updateTime();
    const interval = setInterval(updateTime, 1000);

    return () => clearInterval(interval);
  }, []);
  return (
    <div className={headerClass}>
      <div className="container-fluid">
        <div className="nk-header-wrap" style={nkHeaderWrapStyle}>
          <div className="nk-menu-trigger d-xl-none ms-n1">
            <Toggle
              className="d-none nk-nav-toggle nk-quick-nav-icon"
              icon="menu"
              click={themeUpdate.sidebarVisibility}
            />
          </div>
          <div className="nk-header-app-name">
            <Link to="/">
              <div className="">
                <img height="30px" src={theme.skin === "light" ? connect_dark_logo : connect_white_logo} />
              </div>
            </Link>
          </div>
          <div className="nk-header-menu is-light">
            <div className="nk-header-menu-inner">
              <ul className="nk-menu nk-menu-main">
                <li
                  className={`d-none nk-menu-item ${
                    currentUrl === process.env.PUBLIC_URL + "/" ? "active current-page" : ""
                  }`}
                >
                  <Link to={`${process.env.PUBLIC_URL}/`} className="nk-menu-link">
                    <span className="nk-menu-text">Overview</span>
                  </Link>
                </li>
                <li
                  className={`d-none nk-menu-item ${
                    currentUrl === process.env.PUBLIC_URL + "/components" ? "active current-page" : ""
                  }`}
                >
                  <Link to={`${process.env.PUBLIC_URL}/components`} className="nk-menu-link">
                    <span className="nk-menu-text">Components</span>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="d-flex justify-content-center header-calendar">
            <div style={{ lineHeight: "17px" }}>
              <p className="mb-0 fw-bolder text-blue-color">{time}</p>
              <p className="fs-9px mb-0">{date}</p>
            </div>
            <div className="ms-1">
              <Link to="https://calendar.silocloud.io/">
                <Icon name="calender-date" className="head-icon" />
              </Link>
            </div>
          </div>
          <div className="nk-header-tools align-items-center py-1">
            <ul className="nk-quick-nav">
              <li className="mt-1">
                {fullScreenOn ? (
                  <Icon name="minimize" className="pointer fs-4" onClick={toggleFullScreen} />
                ) : (
                  <Icon name="maximize" className="pointer fs-4" onClick={toggleFullScreen} />
                )}
              </li>
              {!publicMeeting && (
                <li className="notification-dropdown me-n1">
                  <Notification />
                </li>
              )}
              <li className="list-apps-dropdown">
                <AppDropdown />
              </li>
              {!publicMeeting && (
                <li className="user-dropdown">
                  <User />
                </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
