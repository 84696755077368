import React from "react";
import Content from "../../layout/content/Content";
import Head from "../../layout/head/Head";
import { Block, BlockDes, BlockHead, BlockHeadContent, BlockTitle } from "../../components/Component";
import { ShimmerCircularImage, ShimmerThumbnail } from "react-shimmer-effects";

const ConnectShimmer = () => {
  return (
    <React.Fragment>
      <Head title="Connect" />
      <Content>
        <BlockHead size="sm" className="d-none">
          <div className="nk-block-between">
            <BlockHeadContent>
              <BlockTitle page tag="h3">
                {process.env.REACT_APP_PROJECT_NAME} Connect
              </BlockTitle>
              <BlockDes className="text-soft d-none">
                <p>Welcome to Analytics Dashboard.</p>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent></BlockHeadContent>
          </div>
        </BlockHead>

        <Block>
          <div className="position-relative ">
            <ShimmerThumbnail height={500} rounded />
            <ShimmerCircularImage size={150} className="circleImage" />
            <div className="d-flex video-icons">
              <img src="/voice.png" alt="" />
              <img src="/video.png" alt="" />
              <img src="/hang-up.png" alt="" />
            </div>
          </div>
        </Block>
      </Content>
    </React.Fragment>
  );
};

export default ConnectShimmer;
