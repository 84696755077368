import React, { useState } from "react";
import { Icon } from "../components/Component";
import { Tooltip } from "reactstrap";

const TalkComponent = () => {
  const [tooltipOpen, setTooltipOpen] = useState({
    menu: false,
    video: false,
    mic: false,
    call: false,
  });

  // Function to toggle tooltip visibility
  const toggleTooltip = (key) => {
    setTooltipOpen({
      ...tooltipOpen,
      [key]: !tooltipOpen[key],
    });
  };
  return (
    <>
      <div className="d-flex align-items-center footer-icons justify-content-center justify-content-lg-end mb-2">
        <span id="TooltipMenu">
          <Icon name="menu" className="icon" />
          <Tooltip isOpen={tooltipOpen.menu} target="TooltipMenu" toggle={() => toggleTooltip("menu")}>
            Contacts
          </Tooltip>
        </span>

        <span id="TooltipVideo">
          <Icon name="video-fill" className="icon" />
          <Tooltip isOpen={tooltipOpen.video} target="TooltipVideo" toggle={() => toggleTooltip("video")}>
            Video Call
          </Tooltip>
        </span>

        <span id="TooltipMic">
          <Icon name="mic" className="icon" />
          <Tooltip isOpen={tooltipOpen.mic} target="TooltipMic" toggle={() => toggleTooltip("mic")}>
            Mute
          </Tooltip>
        </span>

        <span id="TooltipCall" className="end-call">
          <Icon name="call-fill" className="icon red-icon me-0 text-white" />
          <Tooltip isOpen={tooltipOpen.call} target="TooltipCall" toggle={() => toggleTooltip("call")}>
            End
          </Tooltip>
        </span>
      </div>
    </>
  );
};

export default TalkComponent;
