import React from "react";
import classNames from "classnames";

function AppWrap({ nkWrap = true, styles = {}, className, ...props }) {
  const compClass = classNames({
    "nk-wrap": nkWrap,
    [`${className}`]: className,
  });
  return (
    <div className={compClass} style={styles}>
      {props.children}
    </div>
  );
}

export default AppWrap;
