import { useState, useEffect } from "react";
import { fetchConnectAPI } from "../../utils/Utils";

// import React from "react";

const useUserList = () => {
  const [UserList, setUserList] = useState([]);

  useEffect(() => {
    getUserList();
  }, []);
  const getUserList = async () => {
    try {
      const userData = await fetchConnectAPI(null, `public/users`, false);
      if (userData.status == 200 && userData.response?.status) {
        const userArr = userData?.response?.data?.UserNames;
        const userOptions = userArr.map((user, index) => ({
          value: user,
          label: user,
        }));

        setUserList(userOptions);
      } else {
        console.log("User data is invalid or missing required properties");
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    }
  };
  return UserList;
};

export default useUserList;
