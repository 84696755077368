import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Head from "./head/Head";
import Header from "./header/Header";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import Cookies from "js-cookie";
import { ToastContainer } from "react-toastify";
import Footer from "./footer/Footer";

const ConnectLayout = ({ title, app, ...props }) => {
  const [authChecking, setAuthChecking] = useState(true);
  useEffect(() => {
    let at = Cookies.get("authToken");
    if (!at) {
      const now = new Date();
      const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000); // 24 hours in milliseconds
      Cookies.set("currentURL", window.location.href, {
        domain: process.env.REACT_APP_COOKIE_DOMAIN,
        expires: expires,
      });
      window.location = process.env.REACT_APP_ACCOUNT_URL;
    } else {
      setAuthChecking(false);
    }
  }, []);
  return (
    <>
      <ToastContainer />
      <Head title={!title && "Loading"} />
      <AppRoot>
        <AppMain>
          <AppWrap nkWrap={false} styles={{ paddingTop: "65px" }}>
            <Header fixed publicMeeting={false} />
            <Outlet />
            <Footer fixed />
          </AppWrap>
        </AppMain>
      </AppRoot>
    </>
  );
};
export default ConnectLayout;
